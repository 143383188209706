/**
 * Omniture-specific string separators.
 *
 * @module analytics/separators
 * @author Michael Clayton <mclayton@redhat.com>
 * @copyright Red Hat 2013-2014
 */

// import * as _ from "lodash-es";
// import { map } from "lodash-es";
import map from "lodash-es/map";

const pipe = " | ";
const pipe_nsp = "|";
const dash = "-";
const comma = ", ";
const comma_nsp = ",";
const semicolon = ";";

/**
 * Remove commas from a string.
 *
 * @memberof module:analytics/separators
 * @param {string} s the string to remove commas from.
 * @return {string} the string with commas removed.
 */
function strip_comma(s) {
  return s.replace(comma_nsp, "");
}

/**
 * Remove commas from each string in an array of strings.
 *
 * @memberof module:analytics/separators
 * @param {string[]} a the array of strings to remove commas from.
 * @return {string[]} an array of strings with the commas removed.
 */
function strip_comma_from_array(a) {
  return map(a, strip_comma);
}

/**
 * The string value seaprators.
 *
 * @memberof module:analytics/separators
 * @member {Object}
 */
export default {
  pipe,
  pipe_nsp,
  dash,
  comma,
  comma_nsp,
  semicolon,
  join: function make_join(ch) {
    return function join(list) {
      return list.join(ch);
    };
  },
  strip_comma,
  strip_comma_from_array,
};
