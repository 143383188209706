/**
 * This module is used to triggers pageview analytics.
 *
 * @module analytics/pageview
 * @author Michael Clayton <mclayton@redhat.com>
 * @copyright Red Hat 2014
 */
import paf from "./main";
import attributes from "./attributes";

/**
 * Run basic pageview analytics.
 */
async function run() {
  try {
    await paf.init(["dtm"]);
    attributes.harvest();

    if (paf.auto_report_on()) {
      paf.report();
    } else {
      // .pafoff found on html tag
    }
  } catch (e) {
    console.log(e);
    // most likely we wind up here because of an anti-tracking browser
    // extension.  Honor it.
  }
}

export default {
  run_once: run,
  send: run,
};
