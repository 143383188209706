import session from "./session/session";
import "@cpelements/pfe-navigation";
import "../node_modules/@cpelements/pfe-navigation/dist/pfe-navigation--lightdom.min.css";
import "@cpelements/rh-site-switcher";
import "@cpelements/rh-account-dropdown";
import "@cpelements/cp-search-autocomplete";
import "@patternfly/pfe-primary-detail";
import "@patternfly/pfe-cta";
import "@patternfly/pfe-icon";
import "@patternfly/pfe-avatar";
import * as search from "./search";
import * as cookie from "./cookie";
import localePicker from "./locale-picker";
import { getCurrentLocale } from "./locale"
import DOMReady from "./ready";

export function init() {
  var pfeNavigation = document.getElementById("pfe-navigation");
  var utilityNavigation = document.getElementById("portal-utility-nav");
  var skipToNav = document.getElementById("global-skip-to-nav");

  function navObserverCallback() {
    if (
      !utilityNavigation.hasAttribute("hidden") &&
      pfeNavigation.breakpoint === "mobile"
    ) {
      utilityNavigation.hidden = true;
      skipToNav.setAttribute("href", "#" + pfeNavigation.id);
    } else if (
      (utilityNavigation.hasAttribute("hidden") &&
        pfeNavigation.breakpoint === "tablet") ||
      pfeNavigation.breakpoint === "desktop"
    ) {
      utilityNavigation.hidden = false;
      skipToNav.setAttribute("href", "#" + utilityNavigation.id);
    }
  }

  var navObserver = new MutationObserver(navObserverCallback);
  navObserver.observe(pfeNavigation, {
    attributes: true,
    attributeFilter: ["breakpoint"],
  });

  /**
   * pfe-shadow-dom-event fires for a click on any interactive element that only exists in the shadow dom
   * Reports PFE event to Adobe analytics helper that will log it appropriately
   * @param {Object} event.target - Parent component
   * @param {Object} event.detail.target - The interactive element (button or link) that was clicked
   */
  document.addEventListener("pfe-shadow-dom-event", function (event) {
    // Send PFE analytics data to helper function
    if (
      window.analyticsHelper &&
      typeof window.analyticsHelper.parseCustomEvent === "function"
    ) {
      window.analyticsHelper.parseCustomEvent(
        event.detail.target,
        event.detail.parentComponent,
        "click"
      );
    }
  });

  /**
   * pfe-navigation:expanded-item fires when a dropdown is expanded
   * Reports PFE event to Adobe analytics helper that will log it appropriately
   *
   * All dropdowns are on the first level
   * @param {Object} event.detail.toggle - Reference to button DOM element that expands/collapses the dropdown
   * @param {Object} event.detail.pane - Reference to the dropdown wrapper
   */
  document.addEventListener("pfe-navigation:expanded-item", function (event) {
    // Primary detail gets bogus layout when loading if it's in a dropdown
    // This makes sure it re-checks the layout when a dropdown with a primary detail gets opened
    if (
      event.detail.pane &&
      event.detail.pane.children[0].classList.contains("has-primary-detail")
    ) {
      var primaryDetail = event.detail.parent.querySelector(
        ".main-menu__dropdown--product__product-listing"
      );
      if (typeof primaryDetail._setBreakpoint === "function") {
        primaryDetail._setBreakpoint();
      }
    }

    // Send PFE analytics data to helper function
    if (
      window.analyticsHelper &&
      typeof window.analyticsHelper.parseCustomEvent === "function"
    ) {
      window.analyticsHelper.parseCustomEvent(
        event.detail.toggle,
        event.target,
        "expand"
      );
    }
  });

  var userData = undefined;
  var rhAccountDropdown = document.querySelector("rh-account-dropdown");
  var language = getCurrentLocale().toLowerCase();
  if (language === "zh_cn") {
    language = 'zh';
  }
  if (language === "en_us") {
    language = 'en';
  }

  // Tell the account dropdown which env the links should point to
  let env = '';
  switch (portal.host) {
    case 'https://access.dev.redhat.com':
      env = 'dev';
      break;
    case 'https://access.qa.redhat.com':
      env = 'qa';
      break;
    case 'https://access.stage.redhat.com':
      env = 'stage';
      break;
  }
  if (env) {
    rhAccountDropdown.setAttribute('env', env);
  }

  var pfeNavigation = document.querySelector("pfe-navigation");

  // Define translations to be passed to pfe-navigation
  var navTranslations = {
    en: {
      login: "Log In",
      menu: "Menu",
      search: "Search",
    },
    ja: {
      login: "ログイン",
      menu: "メニュー",
      search: "検索",
    },
    ko: {
      login: "로그인",
      menu: "Menu",
      search: "검색",
    },
    zh: {
      login: "登录",
      menu: "Menu",
      search: "搜索",
    },
    de: {
      login: "Anmelden",
      menu: "Menu",
      search: "Suche",
    },
    fr: {
      login: "Connexion",
      menu: "Menu",
      search: "Rechercher",
    },
    it: {
      login: "Accedi",
      menu: "Menu",
      search: "Cerca",
    },
    es: {
      login: "Iniciar sesión",
      menu: "Menu",
      search: "Buscar",
    },
    pt: {
      login: "Login",
      menu: "Menu",
      search: "Pesquisar",
    },
  };

  /**
   * Setup rh-account-dropdown component with the data it needs
   */
  function setupAuthExperience() {
    // Get token, will return as undefined if not logged in
    userData = session.getToken();

    // Setup login button if we're not logged in
    if (typeof userData === "undefined") {
      rhAccountDropdown.setAttribute("login-link", session.getLoginUrl());
    }
    // Setup logged in experience
    else {
      rhAccountDropdown.setAttribute("logout-link", `javascript:import('/services/primer/js/primer.js').then(({session}) => session.logout())`);
      rhAccountDropdown.userData = userData;
    }
  }

  // Things to do after nav has been loaded
  // Attach translations object to pfe-navigation
  pfeNavigation._navTranslations = navTranslations;

  search.init();

  if (language !== "en") {
    pfeNavigation.setAttribute("lang", language);
  }

  // Things to do after rh-account-dropdown is loaded
  // Setup account dropdown translations
  document.querySelector("rh-account-dropdown").setAttribute("lang", language);

  // Once those have loaded setup the auth experience
  session.onInit(setupAuthExperience);

  const siteSwitcherButtonText = {
    en: 'All Red Hat',
    ko: '모든 Red Hat',
    ja: 'すべての Red Hat',
    zh: '所有红帽',
    fr: 'Tous Red Hat'
  };

  // add site-switcher using JS with the correct language already loaded
  var siteSwitcherWrapper = document.createElement('div');
  siteSwitcherWrapper.setAttribute('slot', 'secondary-links');
  siteSwitcherWrapper.classList.add('pfe-navigation__site-switcher');
  siteSwitcherWrapper.innerHTML =
    '<button class="pfe-navigation__secondary-link">'
    + '<pfe-icon icon="web-icon-grid-3x3" size="sm" aria-hidden="true"></pfe-icon>'
      + siteSwitcherButtonText[language]
    + '</button> <pfe-navigation-dropdown dropdown-width="full">'
    + '<site-switcher lang="' + language + '"></site-switcher></pfe-navigation-dropdown>'

  var siteNav = document.getElementById('pfe-navigation');
  if (siteNav) siteNav.append(siteSwitcherWrapper);

}

/**
 * Code that makes the locale menu work
 */
function addLocaleListeners() {
  [...document.querySelectorAll("#localesMenu a[data-lang]")].forEach(
    (link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault();

        // update the rh_locale cookie and reload the page
        var lang = e.target.getAttribute("data-lang");
        var supported_lang = localePicker.closest(lang);
        cookie.setCookie(
          "rh_locale",
          supported_lang,
          undefined,
          "/",
          ".redhat.com"
        );
        window.location.reload();
      });
    }
  );
}

DOMReady(addLocaleListeners);
