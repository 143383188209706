export interface TargetEnv {
  //@TODO - add interfaces for DTM and TrustArc

  /**
   * The URL to the Customer Portal environment targeted by this Primer build.
   * @example "https://access.qa.redhat.com"
   */
  portal_url: string;

  /**
   * The URL to the Red Hat SSO server that should be used in this environment.
   * @example "https://sso.qa.redhat.com"
   */
  idp_url: string;

  /**
   * The URL to the redhat.com environment that should be linked to by builds
   * targeting this Primer environment.
   * @example "https://www.qa.redhat.com"
   */
  rhdc_url: string;

  /**
   * The name of the environment that Primer was built for.
   */
  env_name: string;

  /**
   * The url of dtm for the environment Primer was built for.
   */
  dtm_url: string;

  /**
   * The url of trust arc for the environment Primer was built for.
   */
  trust_arc_url: string;

  /**
   * Sentry-specific error reporting settings to be used in this environment.
   */
  sentry: SentryEnvSettings;
}

export interface SentryEnvSettings {
  /**
   * The DSN tells the SDK where to send the events. If this value is not
   * provided, the SDK will try to read it from the SENTRY_DSN environment
   * variable. If that variable also does not exist, the SDK will just not
   * send any events.
   *
   * @see https://docs.sentry.io/platforms/javascript/configuration/options/#dsn
   */
  dsn: string;

  /**
   * Configures the sample rate for error events, in the range of 0.0 to 1.0.
   * The default is 1.0 which means that 100% of error events are sent. If
   * set to 0.1 only 10% of error events will be sent. Events are picked
   * randomly.
   * @see https://docs.sentry.io/platforms/javascript/configuration/options/#sample-rate
   */
  sampleRate: number;
}

/**
 * This object contains metadata about the Customer Portal environment.  Each
 * Primer build targets a specific environment.
 */
declare const TARGET_ENV: TargetEnv;

export default TARGET_ENV;
