export default function sendCustomEvent(evt) {
  var event;
  if (document.createEvent && document.body.dispatchEvent) {
    event = document.createEvent("Event");
    event.initEvent(evt, true, true); //can bubble, and is cancellable
    document.body.dispatchEvent(event);
  } else if (window.CustomEvent && document.body.dispatchEvent) {
    event = new CustomEvent(evt, { bubbles: true, cancelable: true });
    document.body.dispatchEvent(event);
  }
}
