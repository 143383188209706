// To be deprecated along with PAF
const getEventTarget = function (e) {
  var trg = e.target || e.srcElement || {};
  if (trg.nodeType == 3) {
    // defeat Safari bug
    trg = trg.parentNode;
  }
  return trg;
};

export default getEventTarget;
