/**
 * DTM-specific values and functions.
 *
 * @module analytics/adapters/dtm/attributes
 * @author Michael Clayton <mclayton@redhat.com>
 * @copyright Red Hat 2016
 */

import separators from "../../separators";
import { set } from "lodash-es";
import sendCustomEvent from "./sendCustomEvent";

var NAME = "dtm",
  prefix = { cp: "cp | " },
  AttributeMap;

AttributeMap = {
  ABTestCampaign: { separator: "pipe_nsp" },
  Category: { path: "digitalData.page.attributes.productResource.category" },
  Channel: { path: "digitalData.page.category.primaryCategory" },
  CertifiedVendorProduct: {
    path: "digitalData.page.attributes.productResource.certifiedVendorProduct",
  },
  Component: { path: "digitalData.page.attributes.productResource.component" },
  ContentSubType: { path: "digitalData.page.category.contentSubType" },
  CustomerNumber: {
    path: "digitalData.user[0].profile[0].profileInfo.accountID",
  },
  ErrorType: { path: "digitalData.page.pageInfo.errorType" },
  Hierarchy: {
    path: "digitalData.page.pageInfo.breadCrumbs",
    separator: "pipe_nsp",
  },
  Language: { path: "digitalData.page.pageInfo.language" },
  Products: { path: "digitalData.page.attributes.productResource.products" },
  PublicationState: {
    path: "digitalData.page.attributes.productResource.publicationStatus",
  },
  ResourceID: { path: "digitalData.page.pageInfo.contentID" },
  ResourceTitle: { path: "digitalData.page.pageInfo.title" },
  ResourceType: { path: "digitalData.page.category.contentType" },
  SBR: { path: "digitalData.page.attributes.productResource.sbr" },
  SeverityLevel: { path: "digitalData.page.pageInfo.severityLevel" },
  Tags: { path: "digitalData.page.attributes.productResource.tags" },
  UserNumber: { path: "digitalData.user[0].profile[0].profileInfo.profileID" },
  // Commented-out attributes have nowhere to go in the DDO
  // Architecture          : {},
  // CampaignExternal      : {},
  // CampaignInternal      : {},
  // CodeVersion           : {},
  // Collection            : {},
  // ErrorChannel          : {},
  // ErrorPageName         : {},
  // ErrorPageType         : {},
  // ErrorReferringChannel : {},
  // FacetedSearchValues   : {},
  // GroupID               : {},
  // InfrastructureStr     : {},
  // KCSState              : {},
  // KnowledgeMeta         : {},
  // NoSearchResults       : {},
  // OmniturePersona       : {},
  // OmnitureVisitor       : {},
  // OnSiteSearch          : {},
  // OnSiteSearchValue     : {},
  // PageName              : {},
  // PageRank              : {},
  // Platform              : {},
  // Rating                : {},
  // TargetURL             : {},
  // ToolName              : {},
  // TrueURL               : {},
  // URL                   : {},
  // UseType               : {},
  // UserLogin             : {},
  // UserSearchEvent       : {},
  // UserSessionID         : {},
  // VideoResolution       : {},
  // VideoSegmentTitle     : {},
  // VideoTitle            : {},
  // VisitNumber           : {},
  // isAuthenticated       : {},
  // isEntitled            : {},
  // isInternal            : {},
  // isSupportEntitled     : {}
};

/**
 * Provided by Search Discovery, DTM consultants.
 */

/**
 * This function defines DTM-specific rules for value formatting.
 *
 */
function prep_value(attribute_name, value) {
  var retval,
    mapped_attr = AttributeMap[attribute_name],
    has_prefix = mapped_attr.hasOwnProperty("prefix"),
    has_separator = mapped_attr.hasOwnProperty("separator"),
    attr_prefix = has_prefix ? prefix[mapped_attr.prefix] : "",
    attr_separator = has_separator
      ? separators[mapped_attr.separator]
      : separators.pipe,
    is_list_prop = mapped_attr.list === true;

  if (value instanceof Array) {
    if (is_list_prop) {
      retval =
        ";" + attr_prefix + separators.strip_comma_from_array(value).join(",;");
    } else {
      retval = attr_prefix + value.join(attr_separator);
    }
  } else {
    retval = attr_prefix + value;
    if (is_list_prop) {
      // a 'list prop' with a single item still needs a ; at the beginning
      retval = ";" + separators.strip_comma(retval);
    }
  }
  return retval.trim();
}

function add_attribute_to_ddo(attribute) {
  var attr_map = AttributeMap[attribute.name];
  if (attr_map) {
    var path = attr_map.path;
    var value = prep_value(attribute.name, attribute.value);
    set_ddo_property(path, value);
  }
}

function set_ddo_property(path, value) {
  if (path) {
    set(window, path, value);
  }
}

function build_ddo(attributes) {
  // _.each(attributes.all, add_attribute_to_ddo);
  Object.values(attributes.all).forEach(add_attribute_to_ddo);
}

function send(attributes_in) {
  // Here be (small) dragons.  Some attributes need some tender
  // love-n-care before we send them off to DTM.  Any per-attribute
  // formatting customizations go here.

  // Only send the first two characters of the Language attribute to
  // DTM.  So sayeth the wise Kwatkins.
  if (typeof attributes_in.all.Language !== "undefined") {
    if (typeof attributes_in.all.Language.value !== "undefined") {
      // TODO revisit this when other adapters are added.  This will
      // affect any adapters that are triggered after DTM.
      attributes_in.all.Language.value = attributes_in.all.Language.value.slice(
        0,
        2
      );
    }
  }

  // The hierarchy attribute needs commas stripped from the individual
  // values, because they are joined with commas
  if (typeof attributes_in.all.Hierarchy !== "undefined") {
    if (typeof attributes_in.all.Hierarchy.value !== "undefined") {
      // TODO revisit this when other adapters are added.  This will
      // affect any adapters that are triggered after DTM.
      //attributes_in.all.Language.value = attributes_in.all.Language.value.slice(0, 2);
      for (var i = 0; i < attributes_in.all.Hierarchy.value.length; i += 1) {
        // Array.map weeps for IE8 :(
        attributes_in.all.Hierarchy.value[i] =
          attributes_in.all.Hierarchy.value[i].replace(/,/g, "");
      }
    }
  }

  // The NoSearchResults attribute is really an "event" in DTM's
  // terminology, and thus belongs in the `s.events` string.  Here, make
  // sure it doesn't overwrite the existing `s.events` string (if any).
  if (typeof attributes_in.all.NoSearchResults !== "undefined") {
    if (typeof attributes_in.all.NoSearchResults.value !== "undefined") {
      if (attributes_in.all.NoSearchResults.value) {
        attributes_in.all.NoSearchResults.value = "event69";
      }
    }
  }

  build_ddo(attributes_in);

  // send all the things
  sendCustomEvent("pageReady");

  return {};
}

export default {
  name: NAME,
  send,
  prep_value,
  attributes: AttributeMap,
};
