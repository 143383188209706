import * as network from "./primer-network";

var search_form;
var search_input;
var homepage_search_form;
var homepage_search_input;
var close;
var utility_cpSearchAutocomplete;
var homepage_cpSearchAutocomplete;

//AB Test
var search_form_AB_test;
var search_input_AB_test;

function padZeroes(number: string): string {
  number = number.trim();
  number = new Array(8 - number.length + 1).join("0") + number;
  return number;
}

function homepage_submit_handler(ev: Event): void {
  var search_term = homepage_search_input.val();
  ev.preventDefault();
  handle_event(ev, search_term);
}

function submit_handler(ev: Event): void {
  var search_term = search_input.val();
  handle_event(ev, search_term);
  close();
}

//AB Test
function submit_handler_AB_test(ev: Event): void {
  var search_term = search_input_AB_test.val();
  handle_event(ev, search_term);
}

function handle_event(ev: Event, search_term: string): void {
  var number;
  var host = (window.portal && window.portal.host) || "";
  ev.preventDefault();
  if (network.online) {
    switch (true) {
      case /^case:\s*(\d{1,})$/.test(search_term):
        number = search_term.split(":")[1];
        number = number.length < 8 ? padZeroes(number) : number;
        window.location.assign(host + "/support/cases/" + number);
        break;
      case /^doc-(\d*)/.test(search_term):
        number = search_term.split("-")[1];
        window.location.assign(host + "/node/" + number);
        break;
      case /^it-(.*)/.test(search_term):
        window.location.assign(host + "/support/cases/list/");
        break;
      default:
        window.location.assign(
          host + "/search/#/?q=" + encodeURIComponent(search_term)
        );
    }
  } else {
    // for offline mode, use the search app params for new unified search app
    // once unified search is deployed to production access.redhat.com,
    // this branch can be moved to the default case above
    window.location.assign(
      `${host}/search/?q=${encodeURIComponent(search_term)}`
    );
  }
}

export function init(): void {
  search_form = document.querySelector("#topSearchForm");
  search_input = document.querySelector("#topSearchInput");
  search_form.addEventListener("submit", submit_handler);

  // //AB Test
  // search_form_AB_test  = document.querySelector('#topSearchFormABTest');
  // search_input_AB_test = document.querySelector('#topSearchInputABTest');
  // search_form_AB_test.addEventListener('submit', submit_handler_AB_test);

  utility_cpSearchAutocomplete = document.querySelector(
    "#topSearchForm cp-search-autocomplete"
  );
  utility_cpSearchAutocomplete.addEventListener(
    "pfe-autocomplete:search-event",
    function (event) {
      var search_term = event.detail.searchValue;
      handle_event(event, search_term);
    }
  );
}

export function init_homepage(): void {
  homepage_search_form = document.querySelector("#homePageSearchForm");
  homepage_search_input = document.querySelector("#search-query");
  homepage_search_form.submit(homepage_submit_handler);

  homepage_cpSearchAutocomplete = document.querySelector(
    "#homePageSearchForm cp-search-autocomplete"
  );
  homepage_cpSearchAutocomplete.addEventListener(
    "pfe-autocomplete:search-event",
    function (event) {
      var search_term = event.detail.searchValue;
      handle_event(event, search_term);
    }
  );
}
