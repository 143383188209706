///////////////
//  Imports  //
///////////////

import "./window.portal";
import { exportGlobal } from "./export-global";
import session from "./session/session";
import * as nav from "./nav";
import * as network from "./primer-network";
import analytics from "./analytics/main";
import pageview from "./analytics/pageview";
import * as toTop from "./to-top";
import env from "./target-env";

///////////////
//  Exports  //
///////////////

export * as components from "./components";
export { onReady } from "./primer-ready";
export { dnt } from "./dnt";
export { default as session } from "./session/session";
export { pageview };
export * as locale from "./locale";

// attach Primer module to window.portal.primer
exportGlobal();

///////////////////////////////////////////
//  Wait, then load low-priority stuff.  //
///////////////////////////////////////////

// setTimeout((): void => {
//   // load the status widget that shows up in the footer
//   if (network.online) {
//     import("./status-widget.js").then(({ init }) => {
//       init({ statusPageId: "dn6mqn7xvzz3" });
//     });
//   }
// }, 2000);

// @ts-ignore -> add a reference for old paf stuff to look at portal.analytics for attribute scraping
if (window.portal) {
  window.portal.analytics = analytics;
}

if (network.online) {
  session.init();
  nav.init();

    ////////////////////////////////////////////////
    // Begin remove this stuff when PAF is removed //
    ////////////////////////////////////////////////
    
        
        // @TODO - this logic should exist in its own module...
        /**
         * Sets the correct navigation state.  This is either set by the page as
         *    siteMapState or base on the application
         * Sets the siteMapState to its default value if it hasn't been set already.
         */

    var ids;
    window.siteMapState = window.siteMapState || "start";
    ids = window.siteMapState.split("/");

    // Make anything with 'support' in it just 'support'
    if (ids[0].indexOf("support") !== -1) {
      ids[0] = "support";
    }

    // Re-route 'knowledge' or any 'products' relative as 'products' now
    if (
      ids[0].indexOf("knowledge") !== -1 ||
      ids[0].indexOf("products") !== -1
    ) {
      ids[0] = "products";
    }
    // this function is async
    pageview.send();
    /*  SPAs need to have a .paf-off class in the body tag before the app mounts
            then remove that class and call this function from primer when the app is "loaded"
            each 'page' should call pageview.send(); once it's ready
            NOTE: this function will never send data as long as the .pafoff class is present on <html>
        */
    
    ////////////////////////////////////////////////
    // End remove this stuff when PAF is removed   //
    ////////////////////////////////////////////////
}

toTop.init();
