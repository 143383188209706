/**
 * DTM-specific values and functions.
 *
 * @module analytics/adapters/dtm/events
 * @author Michael Clayton <mclayton@redhat.com>
 * @copyright Red Hat 2013-2015
 */

import attributes from "../../attributes";
import attrs from "./attributes";
import result from "lodash-es/result";
import mapValues from "lodash-es/mapValues";
import merge from "lodash-es/merge";
import tap from "lodash-es/tap";
import sendCustomEvent from "./sendCustomEvent";
import getEventTarget from "../../../getEventTarget";

const chainableFunctions = {
  merge,
  mapValues,
  tap
}; 

const chain = (input) => {
  let value = input;
  const wrapper = {
    ...mapValues(
      chainableFunctions,
      (f) => (...args) => {
        // lodash always puts input as the first argument
        value = f(value, ...args);
        return wrapper;
      },
    ),
    value: () => value,
  };
  return wrapper;
};

const noop = () => {};

var event_definitions;

function resultMapper(v, k, o) {
  return result(o, k);
}

function createEvent(params) {
  var defaults = {
    ddo: {
      eventInfo: {
        timeStamp: new Date(),
        processed: {
          adobeAnalytics: false,
        },
      },
    },
  };
  // start with defaults, apply params, then map any functions to their
  // return values
  return chain(defaults)
    .merge(params)
    .mapValues(resultMapper) // evaluate any functions in this new object
    .tap(function (obj) {
      // also evaluate any functions on eventInfo properties
      obj.ddo.eventInfo = mapValues(obj.ddo.eventInfo, resultMapper);
    })
    .value();
}

/**
 * This function *creates* a function which retrieves the value of a
 * property on an object.  If the property is a string or object, it merely
 * returns that string or object.  But if it's a function, it will invoke
 * that function.
 *
 * @private
 * @return {function} A function that retrieves the value of the property.
 * @param {object} obj An object you want to retrieve a property from.
 * @param {string} key The name of the property you want to retrieve from obj.
 * @example
 * // An object with a number property, and a function property
 * var obj = {
 *     a: 1,
 *     b: function() { return 2; }
 * };
 * var get_a = get_val(obj, 'a');
 * var get_b = get_val(obj, 'b');
 * get_a();
 * // 1
 * get_b();
 * // 2
 */
function get_val(obj, key) {
  switch (typeof obj[key]) {
    case "string":
      return function () {
        return obj[key];
      };
    case "object":
      return function () {
        return obj[key];
      };
    case "function":
      return function (e) {
        return obj[key](e);
      };
    default:
      return function () {
        return undefined;
      };
  }
}

/**
 * should we call the event.should_call?
 * a helper function for the conditional in trigger()
 *
 * @private
 */
function should_call(event_def) {
  if (!event_def) return false;

  var def = typeof event_def === "function" ? event_def() : event_def;

  if (typeof def.should_call !== "function") {
    return true;
  }

  return def.should_call();
}

function get_attr_val(attr_name) {
  return attrs.prep_value(attr_name, attributes.get(attr_name));
}

event_definitions = {
  OpenSupportCase: {
    customEventName: "caseEvent",
    ddo: {
      eventInfo: {
        eventName: "support case",
        eventAction: "open",
        caseNumber: "undefined",
      },
    },
  },
  OpenSupportCaseSubmit: {
    customEventName: "caseEvent",
    ddo: {
      eventInfo: {
        eventName: "support case",
        eventAction: "submit",
        caseNumber: "undefined",
      },
    },
  },
  OpenSupportCaseRecommendationClick: {
    customEventName: "caseEvent",
    ddo: {
      eventInfo: {
        eventName: "support case",
        eventAction: "recommendation click",
        caseNumber: "undefined",
        recommendationTitle: function (ev) {
          // PCM is sending us invalid events; swallow invalid events
          var retval;
          try {
            var target = getEventTarget(ev);
            retval = target.innerHTML;
          } catch (err) {
            retval = "undefined";
          }
          return retval;
        },
      },
    },
  },
  ABTestSuccess: {
    customEventName: "abTestEvent",
    ddo: {
      eventInfo: {
        eventName: "ab test",
        eventAction: "ab test",
        testStep: "start",
        testVariation: function () {
          return get_attr_val("ABTestCampaign");
        },
      },
    },
  },
  ABTestImpression: {
    customEventName: "abTestEvent",
    ddo: {
      eventInfo: {
        eventName: "ab test",
        eventAction: "ab test",
        testStep: "complete",
        testVariation: function () {
          return get_attr_val("ABTestCampaign");
        },
      },
    },
  },
  // this is the same as ABTestImpression, but they were different in the omniture adapter
  ABTestImpressionAsync: {
    customEventName: "abTestEvent",
    ddo: {
      eventInfo: {
        eventName: "ab test",
        eventAction: "ab test",
        testStep: "complete",
        testVariation: function () {
          return get_attr_val("ABTestCampaign");
        },
      },
    },
  },
  // LabsBegin for Lab Impressions - updated event variable to labName
  LabsBegin: {
    customEventName: "labEvent",
    ddo: {
      eventInfo: {
        eventName: "lab event",
        eventAction: "lab event",
        labStep: "impression",
        labName: function () {
          return get_attr_val("ResourceType");
        },
      },
    },
  },
  // LabsCompletion for Lab Engagement - updated event variable to labName
  LabsCompletion: {
    customEventName: "labEvent",
    ddo: {
      eventInfo: {
        eventName: "lab event",
        eventAction: "lab event",
        labStep: "complete",
        labName: function () {
          return get_attr_val("ResourceType");
        },
      },
    },
  },
  // event triggers when a filter is added or removed
  PortalSearchRefinement: function PortalSearchRefinementEvent(refinementObj) {
    if (!refinementObj) return false;

    return {
      customEventName: "refineResults",
      ddo: {
        eventInfo: {
          eventName: "results refinement",
          eventAction: "refinement",
          refinement: refinementObj.refinements || [],
          resultCount: refinementObj.resultCount || 0,
        },
      },
    };
  },
};

/**
 * Trigger a given event in the DTM adapter.
 *
 * @memberof module:analytics/adapters/dtm/events
 * @param {string} event_name The name of the event to trigger.
 * @param {Event} e The DOM event which caused this function to be called.
 * @param {object} data Any arbitrary data that might need to be passed in.  Not used by the eloqua adapter, currently.
 * @param {function} callback A callback function to be run after this request has completed.
 */
function trigger(event_name, dom_event, data, callback) {
  var value,
    prop_val,
    event_s,
    event_this,
    link_type,
    link_name,
    event_obj,
    event_def,
    target;

  if (event_definitions.hasOwnProperty(event_name)) {
    event_def = get_val(event_definitions, event_name)(data);

    if (should_call(event_def) === false) {
      return;
    }

    event_obj = createEvent(event_def);

    //Push it onto the event array on digitalData object
    window.digitalData = window.digitalData || {};
    digitalData.event = digitalData.event || [];
    digitalData.event.push(event_obj.ddo);

    //Create and dispatch an event trigger using the predefined function
    sendCustomEvent(event_obj.customEventName);
  }
}

export default {
  trigger,
  wipe: noop, // adapters/*/events require this, but it doesn't do anything for DTM
  event_definitions,
  call_event: trigger, // for DTM, call_event and trigger are identical, they just mean something different in the omniture adapter
  add_event_value: noop, // adapters/*/events require this, but it doesn't do anything for DTM
};
